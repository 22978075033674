import image1 from '../assets/Backtoschool_trangtong_mainbanner_T7_Slide_840x320.jpg'
import image2 from '../assets/ThienLong_Platinum_BannerVer1_Slide_840x320.jpg'
import image3 from '../assets/Zenbooks_Thang7_Gold_Banner_Slide_840x320.jpg'

export const ListBanner = [
  { id: 1, img: image1 },
  { id: 2, img: image2 },
  { id: 3, img: image3 },
  { id: 4, img: image1 },
  { id: 5, img: image2 },
  { id: 6, img: image3 },
  { id: 7, img: image1 },
  { id: 8, img: image2 },
  { id: 9, img: image3 },
]
