import axios from 'axios'

// const BASE_URL = 'http://localhost:3000'
// // const BASE_URL = 'http://27.118.27.66/'
const BASE_URL = 'https://book-y2h1.onrender.com/'
const authApi = axios.create({
  baseURL: BASE_URL,
  withCredentials: true,
  // headers: {
  //   'Access-Control-Allow-Origin': '*',
  //   'Content-Type': 'application/json',
  // },
})
export default authApi

authApi.defaults.headers.common['Content-Type'] = 'application/json'

export const signUpUserFn = async (user) => {
  const { data } = await authApi.post('/user/register', user)
  return data
}

export const loginUserFn = async (user) => {
  const { data } = await authApi.post('/auth/login', user)
  return data
}

export const logoutUserFn = async () => {
  const data = await authApi.post('/auth/logout')
  return data
}
