import { QueryClient, useMutation } from '@tanstack/react-query'
import { toast } from 'react-toastify'
import authApi, {
  loginUserFn,
  logoutUserFn,
  signUpUserFn,
} from '../api/authApi'
import Cookies from 'js-cookie'
import { useNavigate } from 'react-router-dom'
import { setUserToLocalStorage } from '../share/localStorage'
const queryClient = new QueryClient()

export const useRegister = () => {
  const navigate = useNavigate()
  const {
    mutate: registerUser,
    data,
    isSuccess,
    isLoading,
  } = useMutation({
    mutationFn: (input) => {
      return signUpUserFn(input)
    },
    onSuccess: () => {
      toast.success('Register success')
      navigate('/login')
    },
    onError: (error) => {
      toast.error(
        typeof error.response.data.message === 'string'
          ? error.response.data.message
          : error.response.data.message[0]
      )
    },
  })
  return { registerUser, data, isSuccess, isLoading }
}

export const useLogin = () => {
  const navigate = useNavigate()
  const {
    mutate: loginUser,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: (user) => {
      return loginUserFn(user)
    },
    onSuccess: (data) => {
      window.location.reload()
      console.log(data.fullName)
      toast.success('Hello ' + data.fullName)
      // Cookies.set('auth-token', data.token)
      setUserToLocalStorage(data)
      navigate('/')
    },
    onError: (error) => {
      toast.error(
        typeof error.response.data.message === 'string'
          ? error.response.data.message
          : error.response.data.message[0]
      )
    },
  })
  return { loginUser, isLoading, isError }
}

export const useLogout = () => {
  const {
    mutate: LogoutUser,
    data,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: () => logoutUserFn(),
    onSuccess: () => {
      // Cookies.remove('auth-token')
      toast.success('Logging out....')
    },
  })
  return { LogoutUser, data, isLoading, isError }
}