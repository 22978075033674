import React from 'react'
import Header from '../../components/Header'
import Table from '../../components/Table'
import DashboardContainer from '../../components/DashboardContainer'
import { Footer } from 'antd/es/layout/layout'

const DashBoard = () => {
  return (
    <div className="bg-slate-100">
      <Header />
      <DashboardContainer />
    </div>
  )
}

export default DashBoard
